<template>
  <div>
    <b-row>
      <b-col>
        <BrunaForm ref="confirmacionRef" :fields="this.confirmacion.fields" :card="this.confirmacion.card" @change="faenaConfirmacionChange" :datePickerFormatOptions="datePickerFormatOptions">
          <template v-slot:footer>
            <b-button variant="primary" class="mt-4 float-right" @click="addNewConfirmacionRol">Agregar</b-button>
          </template>
        </BrunaForm>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col xl="12" md="12">
        <!-- table -->
        <ag-grid-table ref="tablaHistoricoConfirmacionRol" :configUrl="historicoConfirmacionRolConfig" :helper="true"
          :dataUrl="historicoConfirmacionRolData">
        </ag-grid-table>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";
import axios from "@axios";
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import useApiServices from '@/services/useApiServices.js';
import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import EliminarActions from "./actions/EliminarActions.vue";

export default {
  data() {
    return {
      historicoConfirmacionRolConfig: useApiServices.historicoConfirmacionRolConfig,
      historicoConfirmacionRolData: useApiServices.historicoConfirmacionRolData,
      itemsData: [],
      datePickerFormatOptions: {'year': 'numeric', 'month': '2-digit', 'day': '2-digit'},
      confirmacion: {
        card: {
          title: "Confirmación de rol",
          subtitle: "",
          tooltip: "",
          sidebarContent: {
            title: 'Confirmación de rol',
            body: "Para poder hacer un reporte de confirmación de rol debe rellenar los campos y luego hacer clic en botón “agregar”."
          }
        },
        fields: [
          {
            label: "Fecha",
            type: "datepicker",
            id: 'fecha',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Faena",
            type: "select",
            id: 'faena',
            rules: 'required',
            options: [
              { value: null, text: "Seleccione una opción" },
              { value: 'Planta Química de Litio Carmen', text: "Planta Química de Litio Carmen" },
              { value: 'Salar de Atacama', text: "Salar de Atacama" },
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Área de trabajo",
            type: "select",
            id: 'area_trabajo',
            rules: 'required',
            options: [
              { value: null, text: "Seleccione una opción" }
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Tipo de Programa",
            type: "select",
            id: 'tipo_programa',
            rules: 'required',
            options: [
              { value: null, text: "Seleccione una opción" }
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          }
        ]
      },
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    AgGridTable,
    BrunaForm,
    ToastificationContent,
    EliminarActions:EliminarActions
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted() {

  },
  methods: {
    addNewConfirmacionRol(event) {
      let data = this.confirmacion.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})
      // console.log(data);
      Promise.all([
        this.$refs.confirmacionRef.validate(),
      ]).then(([confirmacionValid]) => {
        if (confirmacionValid) {
          useApiServices.postConfirmacionRolCreate(data)
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Carga Exitosa`,
                  icon: "UploadIcon",
                  variant: "success",
                  html: `Se ha guardado el nuevo registro exitosamente.`,
                },
              });
              this.$refs['tablaHistoricoConfirmacionRol'].refreshRows()
            })
            .catch((error) => {
              let message = error;
              console.log(error);
  
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Ocurrio un Error`,
                  icon: "UploadIcon",
                  variant: "danger",
                  html: message,
                },
              });
  
            });
        }
      });
    },
    faenaConfirmacionChange(field) {
      if (field.id == 'faena') {
        let area_trabajo_options = []
        let tipo_programa_options = []
        if (field.value == "Salar de Atacama") {
          area_trabajo_options = [
            { value: null, text: "Seleccione una opción" },
            { value: 'Acopios', text: "Acopios" },
            { value: 'Acopios MOP H I', text: "Acopios MOP H I" },
            { value: 'Bodega Bosata', text: "Bodega Bosata" },
            { value: 'Canchas de Producto', text: "Canchas de Producto" },
            { value: 'CIRS', text: "CIRS" },
            { value: 'COM', text: "COM" },
            { value: 'Control de Productos Salar', text: "Control de Productos Salar" },
            { value: 'Despacho AB', text: "Despacho AB" },
            { value: 'Despacho CD', text: "Despacho CD" },
            { value: 'Despacho Salar', text: "Despacho Salar" },
            { value: 'DPS MOP I', text: "DPS MOP I" },
            { value: 'DPS MOP II', text: "DPS MOP II" },
            { value: 'Encarpadora Romana 1', text: "Encarpadora Romana 1" },
            { value: 'Encarpadora Romana 2', text: "Encarpadora Romana 2" },
            { value: 'Garita Oscar', text: "Garita Oscar" },
            { value: 'Gerencia Hidrogeología', text: "Gerencia Hidrogeología" },
            { value: 'Laboratorio Metalúrgico', text: "Laboratorio Metalúrgico" },
            { value: 'Laboratorio Químico', text: "Laboratorio Químico" },
            { value: 'Mina Norte', text: "Mina Norte" },
            { value: 'Oficinas Cosecha/Pozas Litio/Pozas MOPI', text: "Oficinas Cosecha/Pozas Litio/Pozas MOPI" },
            { value: 'Patio Operaciones Excon MOP I', text: "Patio Operaciones Excon MOP I" },
            { value: 'PDA 5', text: "PDA 5" },
            { value: 'Planta MOP G II', text: "Planta MOP G II" },
            { value: 'Planta MOP G III', text: "Planta MOP G III" },
            { value: 'Planta MOP H II', text: "Planta MOP H II" },
            { value: 'Planta MOP HI', text: "Planta MOP HI" },
            { value: 'Planta PCI', text: "Planta PCI" },
            { value: 'Planta Piloto', text: "Planta Piloto" },
            { value: 'Planta SOP H', text: "Planta SOP H" },
            { value: 'Pozas Litio', text: "Pozas Litio" },
            { value: 'POZAS MOP / SOP III', text: "POZAS MOP / SOP III" },
            { value: 'Pozas MOP I', text: "Pozas MOP I" },
            { value: 'Romana 1 MOP I', text: "Romana 1 MOP I" },
            { value: 'Romana 2 MOP I', text: "Romana 2 MOP I" },
            { value: 'Romana 3 MOP II', text: "Romana 3 MOP II" },
            { value: 'Romana 4 MOP II', text: "Romana 4 MOP II" },
            { value: 'Taller de Mantención Mina', text: "Taller de Mantención Mina" },
            { value: 'Taller Instrumentación', text: "Taller Instrumentación" },
            { value: 'Taller Mantención Hidrogeología', text: "Taller Mantención Hidrogeología" },
            { value: 'Taller Mantención Mecánica MOP I', text: "Taller Mantención Mecánica MOP I" }
          ]
          tipo_programa_options = [
            { value: null, text: "Seleccione una opción" },
            { value: 'Programa Personalizado de Supervisión', text: "Programa Personalizado de Supervisión" },
            { value: 'Plan de Acción de Investigación de Incidentes', text: "Plan de Acción de Investigación de Incidentes" },
            { value: 'Programa de Liderazgo Percibido', text: "Programa de Liderazgo Percibido" }          
          ]
          this.findFieldByName(this.confirmacion.fields, 'area_trabajo').options = area_trabajo_options
          this.findFieldByName(this.confirmacion.fields, 'tipo_programa').options = tipo_programa_options
        }
        else {
          area_trabajo_options = [
          { value: null, text: "Seleccione una opción" },
            { value: 'Bodega de Envasado de Carbonato', text: "Bodega de Envasado de Carbonato" },
            { value: 'Bodega de Envasado de Hidróxido', text: "Bodega de Envasado de Hidróxido" },
            { value: 'Bodega de Salar del Carmen ', text: "Bodega de Salar del Carmen " },
            { value: 'Descarga de Solución de Litio', text: "Descarga de Solución de Litio" },
            { value: 'Embarque de Hidróxido', text: "Embarque de Hidróxido" },
            { value: 'Envasado y Despacho', text: "Envasado y Despacho" },
            { value: 'Galpon de Ceniza', text: "Galpon de Ceniza" },
            { value: 'Gerencia de Procesos', text: "Gerencia de Procesos" },
            { value: 'Gerencia de Produccion Litio', text: "Gerencia de Produccion Litio" },
            { value: 'Gerencia de Proyecto y Mantención', text: "Gerencia de Proyecto y Mantención" },
            { value: 'Laboratorio Quimico', text: "Laboratorio Quimico" },
            { value: 'Mantención Eléctrica', text: "Mantención Eléctrica" },
            { value: 'Mantención Mecánica', text: "Mantención Mecánica" },
            { value: 'Planificacion Mantención', text: "Planificacion Mantención" },
            { value: 'Planta Carbonato', text: "Planta Carbonato" },
            { value: 'Planta Hidróxido', text: "Planta Hidróxido" },
            { value: 'Planta Piloto', text: "Planta Piloto" },
            { value: 'Pozas de Litio', text: "Pozas de Litio" },
            { value: 'Taller de Mantención', text: "Taller de Mantención" },
          ]
          tipo_programa_options = [
            { value: null, text: "Seleccione una opción" },
            { value: 'Programa Personalizado de Supervisión', text: "Programa Personalizado de Supervisión" },
            { value: 'Plan de Acción de Investigación de Incidentes', text: "Plan de Acción de Investigación de Incidentes" },
          ]
          this.findFieldByName(this.confirmacion.fields, 'area_trabajo').options = area_trabajo_options
          this.findFieldByName(this.confirmacion.fields, 'tipo_programa').options = tipo_programa_options
        }        
      }
    },
    findFieldByName(fields, name) {
      return fields.find(field => field.id == name)
    },


  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>
