<template>
  <div>
    <b-row>
      <b-col>
        <BrunaForm ref="hallazgosRef" :fields="this.hallazgos.fields" :card="this.hallazgos.card"
          :datePickerFormatOptions="datePickerFormatOptions" @change="faenaHallazgoChange">
          <template v-slot:footer>
            <b-button variant="primary" class="mt-4 float-right" @click="addNewHallazgo">Agregar</b-button>
          </template>
        </BrunaForm>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col xl="12" md="12">
        <!-- table -->
        <ag-grid-table ref="tablaHistoricoHallazgos" :configUrl="historicoHallazgosConfig" :editUrl="edit" :helper="true"
          :dataUrl="historicoHallazgosData">
        </ag-grid-table>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";
import axios from "@axios";
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import useApiServices from '@/services/useApiServices.js';
import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import EliminarActions from "./actions/EliminarActions.vue";

export default {
  data() {
    return {
      historicoHallazgosConfig: useApiServices.historicoHallazgosConfig,
      historicoHallazgosData: useApiServices.historicoHallazgosData,
      edit: useApiServices.reporteHallazgosEdit,
      itemsData: [],
      datePickerFormatOptions: { 'year': 'numeric', 'month': '2-digit', 'day': '2-digit' },
      hallazgos: {
        card: {
          title: "Hallazgos",
          subtitle: "",
          tooltip: "",
          sidebarContent: {
            title: 'Hallazgos',
            body: "Para poder hacer un reporte de hallazgos debe rellenar los campos y luego hacer clic en botón “agregar”."
          }
        },
        fields: [
          {
            label: "Fecha",
            type: "datepicker",
            id: 'fecha',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Faena",
            type: "select",
            id: 'faena',
            rules: 'required',
            options: [
              { value: null, text: "Seleccione una opción" },
              { value: 'Planta Química de Litio Carmen', text: "Planta Química de Litio Carmen" },
              { value: 'Salar de Atacama', text: "Salar de Atacama" },
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Área de trabajo",
            type: "select",
            id: 'area_trabajo',
            rules: 'required',
            options: [
              { value: null, text: "Seleccione una opción" }
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Potencial",
            type: "select",
            id: 'potencial',
            rules: 'required',
            options: [
              { value: null, text: "Seleccione una opción" }
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Riesgo",
            type: "select",
            id: 'riesgo',
            rules: 'required',
            options: [
              { value: null, text: "Seleccione una opción" }
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Estado",
            type: "select",
            id: 'estado',
            rules: 'required',
            options: [
              { value: null, text: "Seleccione una opción" }
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
        ]
      },
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    AgGridTable,
    BrunaForm,
    ToastificationContent,
    EliminarActions: EliminarActions
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted() {

  },
  methods: {
    addNewHallazgo(event) {
      let data = this.hallazgos.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})
      // console.log(data);

      Promise.all([
        this.$refs.hallazgosRef.validate(),
      ]).then(([hallazgosValid]) => {
        if (hallazgosValid) {
          useApiServices.postHallazgosCreate(data)
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Carga Exitosa`,
                  icon: "UploadIcon",
                  variant: "success",
                  html: `Se ha guardado el nuevo registro exitosamente.`,
                },
              });
              this.$refs['tablaHistoricoHallazgos'].refreshRows()
              /*   this.findFieldByName(this.hallazgos.fields, 'fecha').value = 'null'
                this.findFieldByName(this.hallazgos.fields, 'faena').value = 'null'
                this.findFieldByName(this.hallazgos.fields, 'area_trabajo').value = 'null'
                this.findFieldByName(this.hallazgos.fields, 'potencial').value = 'null'
                this.findFieldByName(this.hallazgos.fields, 'riesgo').value = 'null'
                this.findFieldByName(this.hallazgos.fields, 'estado').value = 'null'
                event.preventDefault(); */
            })
            .catch((error) => {
              let message = error.response.data.message;

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Ocurrio un Error`,
                  icon: "UploadIcon",
                  variant: "danger",
                  html: message,
                },
              });

            });
        }
      });
    },
    faenaHallazgoChange(field) {
      if (field.id == 'faena') {
        let estado_options = []
        let potencial_options = []
        let area_trabajo_options = []
        let riesgo_options = []

        estado_options = [
          { value: null, text: "Seleccione una opción" },
          { value: 'Abierto', text: "Abierto" },
          { value: 'Cerrado', text: "Cerrado" },
          { value: 'Dado de Baja', text: "Dado de Baja" },
          { value: 'Rechazada', text: "Rechazada" },
          { value: 'Verificado', text: "Verificado" },
        ]

        potencial_options = [
          { value: null, text: "Seleccione una opción" },
          { value: '‌Bajo', text: "‌Bajo" },
          { value: '‌Medio', text: "‌Medio" },
          { value: '‌Significativo', text: "‌Significativo" },
        ]
        this.findFieldByName(this.hallazgos.fields, 'estado').options = estado_options
        this.findFieldByName(this.hallazgos.fields, 'potencial').options = potencial_options
        if (field.value == "Salar de Atacama") {
          area_trabajo_options = [
            { value: null, text: "Seleccione una opción" },
            { value: 'Acopios', text: "Acopios" },
            { value: 'Acopios MOP H I', text: "Acopios MOP H I" },
            { value: 'Aeródromo Minsal', text: "Aeródromo Minsal" },
            { value: 'Bodega Bosata', text: "Bodega Bosata" },
            { value: 'Bodega Cirs', text: "Bodega Cirs" },
            { value: 'Bodega de Proyectos', text: "Bodega de Proyectos" },
            { value: 'Bodega Proyectos', text: "Bodega Proyectos" },
            { value: 'Bodegas', text: "Bodegas" },
            { value: 'Camino Circunvalación', text: "Camino Circunvalación" },
            { value: 'Camino Interplantas', text: "Camino Interplantas" },
            { value: 'Camino Principal', text: "Camino Principal" },
            { value: 'Campamento Andino', text: "Campamento Andino" },
            { value: 'Campamento Salar', text: "Campamento Salar" },
            { value: 'Campamento Toconao', text: "Campamento Toconao" },
            { value: 'Canaleta 2', text: "Canaleta 2" },
            { value: 'Canaleta 3', text: "Canaleta 3" },
            { value: 'Canaleta Arpes', text: "Canaleta Arpes" },
            { value: 'Canaleta E02', text: "Canaleta E02" },
            { value: 'Canchas de Producto', text: "Canchas de Producto" },
            { value: 'Cañón del Diablo', text: "Cañón del Diablo" },
            { value: 'Casino Andino', text: "Casino Andino" },
            { value: 'Casino MOP I', text: "Casino MOP I" },
            { value: 'Casino MOP II', text: "Casino MOP II" },
            { value: 'CIRS', text: "CIRS" },
            { value: 'COM', text: "COM" },
            { value: 'Control de Productos Salar', text: "Control de Productos Salar" },
            { value: 'COPEC MOP I', text: "COPEC MOP I" },
            { value: 'COPEC MOP II', text: "COPEC MOP II" },
            { value: 'Despacho AB', text: "Despacho AB" },
            { value: 'Despacho CD', text: "Despacho CD" },
            { value: 'Despacho Salar', text: "Despacho Salar" },
            { value: 'DPS MOP I', text: "DPS MOP I" },
            { value: 'DPS MOP II', text: "DPS MOP II" },
            { value: 'Encarpadora 3', text: "Encarpadora 3" },
            { value: 'Encarpadora Romana 1', text: "Encarpadora Romana 1" },
            { value: 'Encarpadora Romana 2', text: "Encarpadora Romana 2" },
            { value: 'Estación Meteorológica', text: "Estación Meteorológica" },
            { value: 'Estacionamientos Buses', text: "Estacionamientos Buses" },
            { value: 'Garita Campamento Andino', text: "Garita Campamento Andino" },
            { value: 'Garita Eco', text: "Garita Eco" },
            { value: 'Garita Oscar', text: "Garita Oscar" },
            { value: 'Generación', text: "Generación" },
            { value: 'Gerencia Hidrogeología', text: "Gerencia Hidrogeología" },
            { value: 'Gerencia KCL', text: "Gerencia KCL" },
            { value: 'Gerencia Proyectos y Mantención', text: "Gerencia Proyectos y Mantención" },
            { value: 'Grandleasing', text: "Grandleasing" },
            { value: 'Laboratorio Metalúrgico', text: "Laboratorio Metalúrgico" },
            { value: 'Laboratorio Químico', text: "Laboratorio Químico" },
            { value: 'Mina Norte', text: "Mina Norte" },
            { value: 'Mina Sur', text: "Mina Sur" },
            { value: 'Oficinas Cosecha/Pozas Litio/Pozas MOPI', text: "Oficinas Cosecha/Pozas Litio/Pozas MOPI" },
            { value: 'Patio Contratista MOP II', text: "Patio Contratista MOP II" },
            { value: 'Patio Contratistas MOP I', text: "Patio Contratistas MOP I" },
            { value: 'Patio de Maniobras', text: "Patio de Maniobras" },
            { value: 'Patio Operaciones Excon MOP I', text: "Patio Operaciones Excon MOP I" },
            { value: 'Patio Operaciones Excon MOP II', text: "Patio Operaciones Excon MOP II" },
            { value: 'Patio Operaciones MOPI - Litio', text: "Patio Operaciones MOPI - Litio" },
            { value: 'Patio Operaciones Pozas', text: "Patio Operaciones Pozas" },
            { value: 'PDA 3', text: "PDA 3" },
            { value: 'PDA 4', text: "PDA 4" },
            { value: 'PDA 5', text: "PDA 5" },
            { value: 'PDA 7', text: "PDA 7" },
            { value: 'PDA 8', text: "PDA 8" },
            { value: 'Piscinas de Agua', text: "Piscinas de Agua" },
            { value: 'Planta Acido Bórico', text: "Planta Acido Bórico" },
            { value: 'Planta MOP G II', text: "Planta MOP G II" },
            { value: 'Planta MOP G III', text: "Planta MOP G III" },
            { value: 'Planta MOP H II', text: "Planta MOP H II" },
            { value: 'Planta MOP HI', text: "Planta MOP HI" },
            { value: 'Planta MOP STD', text: "Planta MOP STD" },
            { value: 'Planta PAM', text: "Planta PAM" },
            { value: 'Planta PC2', text: "Planta PC2" },
            { value: 'Planta PCI', text: "Planta PCI" },
            { value: 'Planta Piloto', text: "Planta Piloto" },
            { value: 'Planta SOP H', text: "Planta SOP H" },
            { value: 'Plantas OR', text: "Plantas OR" },
            { value: 'Plantas TAS', text: "Plantas TAS" },
            { value: 'Policlínico MOP I', text: "Policlínico MOP I" },
            { value: 'Policlínico MOP II', text: "Policlínico MOP II" },
            { value: 'Poza PBS', text: "Poza PBS" },
            { value: 'Pozas de Agua', text: "Pozas de Agua" },
            { value: 'Pozas Litio', text: "Pozas Litio" },
            { value: 'POZAS MOP / SOP III', text: "POZAS MOP / SOP III" },
            { value: 'Pozas MOP I', text: "Pozas MOP I" },
            { value: 'Romana 1 MOP I', text: "Romana 1 MOP I" },
            { value: 'Romana 2 MOP I', text: "Romana 2 MOP I" },
            { value: 'Romana 3 MOP II', text: "Romana 3 MOP II" },
            { value: 'Romana 4 MOP II', text: "Romana 4 MOP II" },
            { value: 'RRHH KCL', text: "RRHH KCL" },
            { value: 'Ruta B385', text: "Ruta B385" },
            { value: 'Ruta Salar Toconao', text: "Ruta Salar Toconao" },
            { value: 'Salas Eléctricas', text: "Salas Eléctricas" },
            { value: 'SIA', text: "SIA" },
            { value: 'Subestación condensadores 1', text: "Subestación condensadores 1" },
            { value: 'Subestación condensadores 2', text: "Subestación condensadores 2" },
            { value: 'Subestación MINSAL', text: "Subestación MINSAL" },
            { value: 'Taller de Mantención Mina', text: "Taller de Mantención Mina" },
            { value: 'Taller de Mantención MOP II', text: "Taller de Mantención MOP II" },
            { value: 'Taller de Soldadura', text: "Taller de Soldadura" },
            { value: 'Taller Eléctrico', text: "Taller Eléctrico" },
            { value: 'Taller Instrumentación', text: "Taller Instrumentación" },
            { value: 'Taller Mantención Eléctrica MOP I', text: "Taller Mantención Eléctrica MOP I" },
            { value: 'Taller Mantención Hidrogeología', text: "Taller Mantención Hidrogeología" },
            { value: 'Taller Mantención Instrumentación MOP I', text: "Taller Mantención Instrumentación MOP I" },
            { value: 'Taller Mantención Mecánica MOP I', text: "Taller Mantención Mecánica MOP I" },
            { value: 'Taller Mecánico', text: "Taller Mecánico" },
            { value: 'Terrapuerto', text: "Terrapuerto" }
          ]
          riesgo_options = [
            { value: null, text: "Seleccione una opción" },
            { value: 'Aplastamiento', text: "Aplastamiento" },
            { value: '‌Aprisionamiento', text: "‌Aprisionamiento" },
            { value: '‌Atrapamiento', text: "‌Atrapamiento" },
            { value: 'Atropello de Animales', text: "Atropello de Animales" },
            { value: '‌Atropello de Personas', text: "‌Atropello de Personas" },
            { value: '‌Caída de Distinto Nivel', text: "‌Caída de Distinto Nivel" },
            { value: '‌Caída Mismo Nivel', text: "‌Caída Mismo Nivel" },
            { value: '‌Camión en Panne', text: "‌Camión en Panne" },
            { value: '‌Choque', text: "‌Choque" },
            { value: '‌Colisión / Choque de vehículo liviano', text: "‌Colisión / Choque de vehículo liviano" },
            { value: '‌Colisión / Choque de Vehículo pesado', text: "‌Colisión / Choque de Vehículo pesado" },
            { value: 'Condición sub-estándar de Activo', text: "Condición sub-estándar de Activo" },
            { value: '‌Contacto con Alta Temperatura', text: "‌Contacto con Alta Temperatura" },
            { value: '‌Contacto con Energía Eléctrica', text: "‌Contacto con Energía Eléctrica" },
            { value: '‌Contacto con Objeto Cortopunzante', text: "‌Contacto con Objeto Cortopunzante" },
            { value: '‌Contacto con Partículas Incandescentes', text: "‌Contacto con Partículas Incandescentes" },
            { value: '‌Contacto con Sustancia Química', text: "‌Contacto con Sustancia Química" },
            { value: 'Contacto con Sustancias Infecciosas', text: "Contacto con Sustancias Infecciosas" },
            { value: '‌Contacto por Alta Temperatura', text: "‌Contacto por Alta Temperatura" },
            { value: '‌Contacto por Energía Eléctrica', text: "‌Contacto por Energía Eléctrica" },
            { value: '‌Contacto por Objeto Cortopunzante', text: "‌Contacto por Objeto Cortopunzante" },
            { value: '‌Contacto por Partículas Incandescentes', text: "‌Contacto por Partículas Incandescentes" },
            { value: '‌Contacto por Sustancia Química', text: "‌Contacto por Sustancia Química" },
            { value: '‌Contacto por Sustancias Infecciosas', text: "‌Contacto por Sustancias Infecciosas" },
            { value: 'Continuidad Operacional', text: "Continuidad Operacional" },
            { value: '‌CORONAVIRUS', text: "‌CORONAVIRUS" },
            { value: '‌Exposición a Gases Tóxicos', text: "‌Exposición a Gases Tóxicos" },
            { value: 'Exposición a lugar con falta de Oxígeno', text: "Exposición a lugar con falta de Oxígeno" },
            { value: 'Exposición a Niveles de Presión Anormales', text: "Exposición a Niveles de Presión Anormales" },
            { value: '‌Exposición a Niveles Nocivos de Ruido', text: "‌Exposición a Niveles Nocivos de Ruido" },
            { value: 'Exposición a Niveles Nocivos de Vibraciones', text: "Exposición a Niveles Nocivos de Vibraciones" },
            { value: '‌Exposición a partículas nocivas en suspensión', text: "‌Exposición a partículas nocivas en suspensión" },
            { value: '‌Exposición a Radiación UV', text: "‌Exposición a Radiación UV" },
            { value: '‌Exposición a Radiaciones Ionizantes', text: "‌Exposición a Radiaciones Ionizantes" },
            { value: 'Exposición a Temperaturas Extremas', text: "Exposición a Temperaturas Extremas" },
            { value: '‌Golpeado Contra', text: "‌Golpeado Contra" },
            { value: '‌Golpeado Por', text: "‌Golpeado Por" },
            { value: '‌Incendio', text: "‌Incendio" },
            { value: 'Intoxicación', text: "Intoxicación" },
            { value: 'Picadura de Insecto', text: "Picadura de Insecto" },
            { value: '‌Prendimiento de Ropa', text: "‌Prendimiento de Ropa" },
            { value: '‌Quiebre de Abrazadera', text: "‌Quiebre de Abrazadera" },
            { value: '‌Rotura de flexible', text: "‌Rotura de flexible" },
            { value: '‌Sobre Esfuerzo', text: "‌Sobre Esfuerzo" },
            { value: '‌Volcamiento de Vehículo', text: "‌Volcamiento de Vehículo" },
            { value: 'ránsito o evacuación', text: "ránsito o evacuación" },
            { value: 'No ejecuta controles operacionales de procedimientos y/o estándares', text: "No ejecuta controles operacionales de procedimientos y/o estándares" },
            { value: 'No ejecuta instrucciones recibidas para el trabajo /actividad', text: "No ejecuta instrucciones recibidas para el trabajo /actividad" },
            { value: 'No identifica los peligros y/o riesgos del entorno del trabajo (HCR)', text: "No identifica los peligros y/o riesgos del entorno del trabajo (HCR)" },
            { value: 'No identifica los peligros y/o riesgos del trabajo /actividad (ART)', text: "No identifica los peligros y/o riesgos del trabajo /actividad (ART)" },
            { value: 'No implementa controles para peligros y/o riesgos identificados', text: "No implementa controles para peligros y/o riesgos identificados" },
            { value: 'No realiza Checklist de pre-uso', text: "No realiza Checklist de pre-uso" },
            { value: 'No realiza programa de mantención', text: "No realiza programa de mantención" },
            { value: 'No repone protecciones en equipos y/o pisos', text: "No repone protecciones en equipos y/o pisos" },
            { value: 'No respeta permisos y/o autorizaciones', text: "No respeta permisos y/o autorizaciones" },
            { value: 'No respeta señalización(es) prohibitiva(s)', text: "No respeta señalización(es) prohibitiva(s)" },
            { value: 'No respeta velocidad máxima permitida', text: "No respeta velocidad máxima permitida" },
            { value: 'No retira u ordena correctamente equipos utilizados', text: "No retira u ordena correctamente equipos utilizados" },
            { value: 'No retira u ordena correctamente herramientas utilizadas', text: "No retira u ordena correctamente herramientas utilizadas" },
            { value: 'No retira u ordena correctamente accesorios utilizados', text: "No retira u ordena correctamente accesorios utilizados" },
            { value: 'No retira u ordena correctamente materiales utilizados', text: "No retira u ordena correctamente materiales utilizados" },
            { value: 'No retira y dispone correctamente residuos en lugares autorizados', text: "No retira y dispone correctamente residuos en lugares autorizados" },
            { value: 'No utiliza los equipos y/o herramientas de trabajo correctos', text: "No utiliza los equipos y/o herramientas de trabajo correctos" },
            { value: 'No utiliza cinturón de seguridad', text: "No utiliza cinturón de seguridad" },
            { value: 'No utiliza los EPP requeridos para la Tarea /Actividad', text: "No utiliza los EPP requeridos para la Tarea /Actividad" },
            { value: 'No utiliza protección auditiva requerida', text: "No utiliza protección auditiva requerida" },
            { value: 'No utiliza protección respiratoria requerida', text: "No utiliza protección respiratoria requerida" },
            { value: 'No utiliza sistema de comunicaciones', text: "No utiliza sistema de comunicaciones" },
            { value: 'No verifica protecciones en equipos y/o pisos', text: "No verifica protecciones en equipos y/o pisos" },
            { value: 'Opera /trabaja a velocidades inseguras /inadecuadas', text: "Opera /trabaja a velocidades inseguras /inadecuadas" },
            { value: '‌Se distrae durante el trabajo /actividad', text: "‌Se distrae durante el trabajo /actividad" },
            { value: 'Se expone en la Línea de fuego', text: "Se expone en la Línea de fuego" },
            { value: '‌Aplastamiento', text: "‌Aplastamiento" },
            { value: '‌Aprisionamiento', text: "‌Aprisionamiento" },
            { value: '‌Atrapamiento', text: "‌Atrapamiento" },
            { value: 'Atropello de Animales', text: "Atropello de Animales" },
            { value: '‌Atropello de Personas', text: "‌Atropello de Personas" },
            { value: '‌Caída de Distinto Nivel', text: "‌Caída de Distinto Nivel" },
            { value: '‌Caída Mismo Nivel', text: "‌Caída Mismo Nivel" },
            { value: '‌Camión en Panne', text: "‌Camión en Panne" },
            { value: '‌Choque', text: "‌Choque" },
            { value: '‌Colisión / Choque de vehículo liviano', text: "‌Colisión / Choque de vehículo liviano" },
            { value: '‌Colisión / Choque de Vehículo pesado', text: "‌Colisión / Choque de Vehículo pesado" },
            { value: 'Condición sub-estándar de Activo', text: "Condición sub-estándar de Activo" },
            { value: '‌Contacto con Alta Temperatura', text: "‌Contacto con Alta Temperatura" },
            { value: '‌Contacto con Energía Eléctrica', text: "‌Contacto con Energía Eléctrica" },
            { value: '‌Contacto con Objeto Cortopunzante', text: "‌Contacto con Objeto Cortopunzante" },
            { value: '‌Contacto con Partículas Incandescentes', text: "‌Contacto con Partículas Incandescentes" },
            { value: '‌Contacto con Sustancia Química', text: "‌Contacto con Sustancia Química" },
            { value: 'Contacto con Sustancias Infecciosas', text: "Contacto con Sustancias Infecciosas" },
            { value: 'Contacto por Alta Temperatura', text: "Contacto por Alta Temperatura" },
            { value: '‌Contacto por Energía Eléctrica', text: "‌Contacto por Energía Eléctrica" },
            { value: '‌Contacto por Objeto Cortopunzante', text: "‌Contacto por Objeto Cortopunzante" },
            { value: '‌Contacto por Partículas Incandescentes', text: "‌Contacto por Partículas Incandescentes" },
            { value: '‌Contacto por Sustancia Química', text: "‌Contacto por Sustancia Química" },
            { value: '‌Contacto por Sustancias Infecciosas', text: "‌Contacto por Sustancias Infecciosas" },
            { value: 'Continuidad Operacional', text: "Continuidad Operacional" },
            { value: '‌CORONAVIRUS', text: "‌CORONAVIRUS" },
            { value: '‌Exposición a Gases Tóxicos', text: "‌Exposición a Gases Tóxicos" },
            { value: 'Exposición a lugar con falta de Oxígeno', text: "Exposición a lugar con falta de Oxígeno" },
            { value: 'Exposición a Niveles de Presión Anormales', text: "Exposición a Niveles de Presión Anormales" },
            { value: '‌Exposición a Niveles Nocivos de Ruido', text: "‌Exposición a Niveles Nocivos de Ruido" },
            { value: '‌Exposición a Niveles Nocivos de Vibraciones', text: "‌Exposición a Niveles Nocivos de Vibraciones" },
            { value: '‌Exposición a partículas nocivas en suspensión', text: "‌Exposición a partículas nocivas en suspensión" },
            { value: '‌Exposición a Radiación UV', text: "‌Exposición a Radiación UV" },
            { value: '‌Exposición a Radiaciones Ionizantes', text: "‌Exposición a Radiaciones Ionizantes" },
            { value: 'Exposición a Temperaturas Extremas', text: "Exposición a Temperaturas Extremas" },
            { value: '‌Golpeado Contra', text: "‌Golpeado Contra" },
            { value: '‌Golpeado Por', text: "‌Golpeado Por" },
            { value: '‌Incendio', text: "‌Incendio" },
            { value: 'Intoxicación', text: "Intoxicación" },
            { value: 'Picadura de Insecto', text: "Picadura de Insecto" },
            { value: '‌Prendimiento de Ropa', text: "‌Prendimiento de Ropa" },
            { value: '‌Quiebre de Abrazadera', text: "‌Quiebre de Abrazadera" },
            { value: '‌Rotura de flexible', text: "‌Rotura de flexible" },
            { value: '‌Sobre Esfuerzo', text: "‌Sobre Esfuerzo" },
            { value: '‌Volcamiento de Vehículo', text: "‌Volcamiento de Vehículo" }
          ]
          this.findFieldByName(this.hallazgos.fields, 'area_trabajo').options = area_trabajo_options
          this.findFieldByName(this.hallazgos.fields, 'riesgo').options = riesgo_options
        }
        else {
          area_trabajo_options = [
            { value: null, text: "Seleccione una opción" },
            { value: 'Administracion de Campamentos', text: "Administracion de Campamentos" },
            { value: 'Area de Micronizado', text: "Area de Micronizado" },
            { value: 'Barrio Contratistas ', text: "Barrio Contratistas " },
            { value: 'Bodega de Envasado de Carbonato', text: "Bodega de Envasado de Carbonato" },
            { value: 'Bodega de Envasado de Hidróxido', text: "Bodega de Envasado de Hidróxido" },
            { value: 'Bodega de Salar del Carmen ', text: "Bodega de Salar del Carmen " },
            { value: 'Bodega de sustancias Peligrosas', text: "Bodega de sustancias Peligrosas" },
            { value: 'Casino Carbonato de Litio', text: "Casino Carbonato de Litio" },
            { value: 'Descarga de Solución de Litio', text: "Descarga de Solución de Litio" },
            { value: 'Embarque de Carbonato', text: "Embarque de Carbonato" },
            { value: 'Embarque de Hidróxido', text: "Embarque de Hidróxido" },
            { value: 'Envasado y Despacho', text: "Envasado y Despacho" },
            { value: 'Estacion de servicio', text: "Estacion de servicio" },
            { value: 'Galpon de Ceniza', text: "Galpon de Ceniza" },
            { value: 'Gerencia de Procesos', text: "Gerencia de Procesos" },
            { value: 'Gerencia de Produccion Litio', text: "Gerencia de Produccion Litio" },
            { value: 'Gerencia de Proyecto y Mantención', text: "Gerencia de Proyecto y Mantención" },
            { value: 'Laboratorio Quimico', text: "Laboratorio Quimico" },
            { value: 'Mantención Eléctrica', text: "Mantención Eléctrica" },
            { value: 'Mantención Mecánica', text: "Mantención Mecánica" },
            { value: 'Patio de Maxi sacos', text: "Patio de Maxi sacos" },
            { value: 'Patio de Pallet', text: "Patio de Pallet" },
            { value: 'Patio de residuos ', text: "Patio de residuos " },
            { value: 'Patios de Chatarra', text: "Patios de Chatarra" },
            { value: 'Planta Carbonato', text: "Planta Carbonato" },
            { value: 'Planta Hidróxido', text: "Planta Hidróxido" },
            { value: 'Planta Piloto', text: "Planta Piloto" },
            { value: 'Planta SX', text: "Planta SX" },
            { value: 'Pozas de Descartes', text: "Pozas de Descartes" },
            { value: 'Pozas de Litio', text: "Pozas de Litio" },
            { value: 'Romana', text: "Romana" },
            { value: 'Sala de Cambio de Carbonato', text: "Sala de Cambio de Carbonato" },
            { value: 'Sala de Cambio de Hidróxido', text: "Sala de Cambio de Hidróxido" },
            { value: 'Superintendencia Producción - Planta Carbonato', text: "Superintendencia Producción - Planta Carbonato" },
            { value: 'Superintendencia Producción - Planta Hidróxido', text: "Superintendencia Producción - Planta Hidróxido" },
            { value: 'Taller de Mantención', text: "Taller de Mantención" },
            { value: 'TK de Acido Clorhidrico', text: "TK de Acido Clorhidrico" },
            { value: 'TK de Acido Sulfurico', text: "TK de Acido Sulfurico" },
            { value: 'TK de Pretroleo', text: "TK de Pretroleo" },
          ]
          riesgo_options = [
            { value: null, text: "Seleccione una opción" },
            { value: 'Aplastamiento', text: "Aplastamiento" },
            { value: '‌Aprisionamiento', text: "‌Aprisionamiento" },
            { value: '‌Atrapamiento', text: "‌Atrapamiento" },
            { value: 'Atropello de Animales', text: "Atropello de Animales" },
            { value: '‌Atropello de Personas', text: "‌Atropello de Personas" },
            { value: '‌Caída de Distinto Nivel', text: "‌Caída de Distinto Nivel" },
            { value: '‌Caída Mismo Nivel', text: "‌Caída Mismo Nivel" },
            { value: '‌Camión en Panne', text: "‌Camión en Panne" },
            { value: '‌Choque', text: "‌Choque" },
            { value: '‌Colisión / Choque de vehículo liviano', text: "‌Colisión / Choque de vehículo liviano" },
            { value: '‌Colisión / Choque de Vehículo pesado', text: "‌Colisión / Choque de Vehículo pesado" },
            { value: 'Condición sub-estándar de Activo', text: "Condición sub-estándar de Activo" },
            { value: '‌Contacto con Alta Temperatura', text: "‌Contacto con Alta Temperatura" },
            { value: '‌Contacto con Energía Eléctrica', text: "‌Contacto con Energía Eléctrica" },
            { value: '‌Contacto con Objeto Cortopunzante', text: "‌Contacto con Objeto Cortopunzante" },
            { value: '‌Contacto con Partículas Incandescentes', text: "‌Contacto con Partículas Incandescentes" },
            { value: '‌Contacto con Sustancia Química', text: "‌Contacto con Sustancia Química" },
            { value: 'Contacto con Sustancias Infecciosas', text: "Contacto con Sustancias Infecciosas" },
            { value: '‌Contacto por Alta Temperatura', text: "‌Contacto por Alta Temperatura" },
            { value: '‌Contacto por Energía Eléctrica', text: "‌Contacto por Energía Eléctrica" },
            { value: '‌Contacto por Objeto Cortopunzante', text: "‌Contacto por Objeto Cortopunzante" },
            { value: '‌Contacto por Partículas Incandescentes', text: "‌Contacto por Partículas Incandescentes" },
            { value: '‌Contacto por Sustancia Química', text: "‌Contacto por Sustancia Química" },
            { value: '‌Contacto por Sustancias Infecciosas', text: "‌Contacto por Sustancias Infecciosas" },
            { value: 'Continuidad Operacional', text: "Continuidad Operacional" },
            { value: '‌CORONAVIRUS', text: "‌CORONAVIRUS" },
            { value: '‌Exposición a Gases Tóxicos', text: "‌Exposición a Gases Tóxicos" },
            { value: 'Exposición a lugar con falta de Oxígeno', text: "Exposición a lugar con falta de Oxígeno" },
            { value: 'Exposición a Niveles de Presión Anormales', text: "Exposición a Niveles de Presión Anormales" },
            { value: '‌Exposición a Niveles Nocivos de Ruido', text: "‌Exposición a Niveles Nocivos de Ruido" },
            { value: 'Exposición a Niveles Nocivos de Vibraciones', text: "Exposición a Niveles Nocivos de Vibraciones" },
            { value: '‌Exposición a partículas nocivas en suspensión', text: "‌Exposición a partículas nocivas en suspensión" },
            { value: '‌Exposición a Radiación UV', text: "‌Exposición a Radiación UV" },
            { value: '‌Exposición a Radiaciones Ionizantes', text: "‌Exposición a Radiaciones Ionizantes" },
            { value: 'Exposición a Temperaturas Extremas', text: "Exposición a Temperaturas Extremas" },
            { value: '‌Golpeado Contra', text: "‌Golpeado Contra" },
            { value: '‌Golpeado Por', text: "‌Golpeado Por" },
            { value: '‌Incendio', text: "‌Incendio" },
            { value: 'Intoxicación', text: "Intoxicación" },
            { value: 'Picadura de Insecto', text: "Picadura de Insecto" },
            { value: '‌Prendimiento de Ropa', text: "‌Prendimiento de Ropa" },
            { value: '‌Quiebre de Abrazadera', text: "‌Quiebre de Abrazadera" },
            { value: '‌Rotura de flexible', text: "‌Rotura de flexible" },
            { value: '‌Sobre Esfuerzo', text: "‌Sobre Esfuerzo" },
            { value: '‌Volcamiento de Vehículo', text: "‌Volcamiento de Vehículo" },
          ]
          this.findFieldByName(this.hallazgos.fields, 'area_trabajo').options = area_trabajo_options
          this.findFieldByName(this.hallazgos.fields, 'riesgo').options = riesgo_options
        }
      }
    },
    findFieldByName(fields, name) {
      return fields.find(field => field.id == name)
    },


  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>
