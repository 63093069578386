<template>
  <div>
    <b-row class="mt-2">
      <b-col xl="12" md="12">
        <!-- table -->
        <ag-grid-table ref="tablaHistoricoGlobal" :configUrl="historicoGlobalConfig" :helper="true"
          :dataUrl="historicoGlobalData">
        </ag-grid-table>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";
import axios from "@axios";
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import useApiServices from '@/services/useApiServices.js';

export default {
  data() {
    return {
      historicoGlobalConfig: useApiServices.historicoGlobalConfig,
      historicoGlobalData: useApiServices.historicoGlobalData
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    AgGridTable,
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted() {

  },
  methods: {},
};
</script>
  
<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>
  