<template>
  <div>
    <b-row>
      <b-col>
        <BrunaForm ref="incidentesRef" :fields="this.incidentes.fields" :card="this.incidentes.card"
          @change="faenaChange" :datePickerFormatOptions="datePickerFormatOptions">
          <template v-slot:footer>
            <b-button variant="primary" class="mt-4 float-right" @click="addNewIncidente">Agregar</b-button>
          </template>
        </BrunaForm>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col xl="12" md="12">
        <!-- table -->
        <ag-grid-table ref="tablaHistoricoIncidentes" :configUrl="historicoIncidentesConfig" :editUrl="edit" :helper="true"
          :dataUrl="historicoIncidentesData">
          <!-- <template v-slot:actions>
              <div class="heading-elements">
                <ul class="list-inline text-right">
                  <li>
                    <feather-icon icon="HelpCircleIcon" v-b-toggle.sidebar-1 size="20" />
                  </li>
                </ul>
              </div>
              <b-sidebar id="sidebar-1" title="Histórico Incidentes" shadow bg-variant="dark" text-variant="light"
                width="450px" right backdrop>
                <div class="px-2 py-2">
                 <ul>
                  <li>Solo se puede modificar las variables “Categoría” y “Potencial” dado que son las únicas genéricas para todas las Faenas.</li>
                  <li>En caso de equivocación en otro campo puede apretar el botón “Eliminar”.</li>
                 </ul>
                </div>
              </b-sidebar>
          </template> -->
        </ag-grid-table>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";
import axios from "@axios";
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import useApiServices from '@/services/useApiServices.js';
import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import EliminarActions from "./actions/EliminarActions.vue";



export default {
  data() {
    return {
      historicoIncidentesConfig: useApiServices.historicoIncidentesConfig,
      historicoIncidentesData: useApiServices.historicoIncidentesData,
      edit: useApiServices.reporteIndidentesEdit,
      itemsData: [],
      datePickerFormatOptions: {'year': 'numeric', 'month': '2-digit', 'day': '2-digit'},
      incidentes: {
        card: {
          title: "Incidentes",
          subtitle: "",
          tooltip: "",
          sidebarContent: {
            title: 'Incidentes',
            body: "Para poder hacer un reporte de incidentes debe rellenar los campos y luego hacer clic en botón “agregar”."
          }
        },
        fields: [
          {
            label: "Fecha",
            type: "datepicker",
            id: 'fecha',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Faena",
            type: "select",
            id: 'faena',
            rules: 'required',
            options: [
              { value: null, text: "Seleccione una opción" },
              { value: 'Planta Química de Litio Carmen', text: "Planta Química de Litio Carmen" },
              { value: 'Salar de Atacama', text: "Salar de Atacama" },
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Área de trabajo",
            type: "select",
            id: 'area_trabajo',
            rules: 'required',
            options: [
              { value: null, text: "Seleccione una opción" }
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Categoría",
            type: "select",
            id: 'categoria',
            rules: 'required',
            options: [
              { value: null, text: "Seleccione una opción" }
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Potencial",
            type: "select",
            id: 'potencial',
            rules: 'required',
            options: [
              { value: null, text: "Seleccione una opción" }
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Riesgo",
            type: "select",
            id: 'riesgo',
            rules: 'required',
            options: [
              { value: null, text: "Seleccione una opción" }
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },

        ]
      },
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    AgGridTable,
    BrunaForm,
    ToastificationContent,
    EliminarActions: EliminarActions,
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted() {

  },
  methods: {
    addNewIncidente(event) {
      let data = this.incidentes.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})
      // console.log(data);
      Promise.all([
        this.$refs.incidentesRef.validate(),
      ]).then(([incidentesValid]) => {
        if (incidentesValid) {
          useApiServices.postIncidentesCreate(data)
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Carga Exitosa`,
                  icon: "UploadIcon",
                  variant: "success",
                  html: `Se ha guardado el nuevo registro exitosamente.`,
                },
              });
              this.$refs['tablaHistoricoIncidentes'].refreshRows()
            })
            .catch((error) => {
              let message = error;
              // console.log(error);
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Ocurrio un Error`,
                  icon: "UploadIcon",
                  variant: "danger",
                  html: message,
                },
              });

            });
        }
      });
    },
    faenaChange(field) {
      if (field.id == 'faena') {

        let categoria_options = [
          { value: null, text: "Seleccione una opción" },
          { value: '‌Accidente con Lesión', text: "‌Accidente con Lesión" },
          { value: '‌Accidente de Tránsito', text: "‌Accidente de Tránsito" },
          { value: '‌Accidente de Trayecto', text: "‌Accidente de Trayecto" },
          { value: '‌Conducta Insegura', text: "‌Conducta Insegura" },
          { value: '‌CTP (con tiempo perdido)', text: "‌CTP (con tiempo perdido)" },
          { value: '‌Cuasi Accidente', text: "‌Cuasi Accidente" },
          { value: '‌Daño Material', text: "‌Daño Material" },
          { value: '‌En Evaluación', text: "‌En Evaluación" },
          { value: '‌Falla Operacional', text: "‌Falla Operacional" },
          { value: '‌N.A.T (no accidente del trabajo)', text: "‌N.A.T (no accidente del trabajo)" },
          { value: '‌N/A (ninguna de las anteriores)', text: "‌N/A (ninguna de las anteriores)" },
          { value: '‌STP (sin tiempo perdido)', text: "‌STP (sin tiempo perdido)" },
        ]
        let potencial_options = [
          { value: null, text: "Seleccione una opción" },
          { value: '‌Leve', text: "‌Leve" },
          { value: '‌Medio', text: "‌Medio" },
          { value: '‌Significativo', text: "‌Significativo" },
        ]
        this.findFieldByName(this.incidentes.fields, 'categoria').options = categoria_options
        this.findFieldByName(this.incidentes.fields, 'potencial').options = potencial_options

        // console.log(field);
        if (field.value == "Salar de Atacama") {
          let area_trabajo_options = [
            { value: null, text: "Seleccione una opción" },
            { value: 'Acopios', text: "Acopios" },
            { value: '‌Acopios MOP H I', text: "‌Acopios MOP H I" },
            { value: '‌Bodega Bosata', text: "‌‌Bodega Bosata" },
            { value: '‌Bodega Cirs', text: "‌‌Bodega Cirs" },
            { value: '‌Bodega de Proyectos', text: "‌‌Bodega de Proyectos" },
            { value: '‌Bodega Proyectos', text: "‌‌Bodega Proyectos" },
            { value: '‌Camino Interplantas', text: "‌‌Camino Interplantas" },
            { value: '‌Camino Principal', text: "‌‌Camino Principal" },
            { value: '‌Campamento Andino', text: "‌‌Campamento Andino" },
            { value: '‌Campamento Salar', text: "‌‌Campamento Salar" },
            { value: '‌Canaleta 2', text: "‌‌Canaleta 2" },
            { value: '‌Canaleta 3', text: "‌‌Canaleta 3" },
            { value: '‌Canaleta Arpes', text: "Canaleta Arpes" },
            { value: '‌Canchas de Producto', text: "‌Canchas de Producto" },
            { value: '‌Casino Andino', text: "‌Casino Andino" },
            { value: '‌Casino MOP I', text: "‌Casino MOP I" },
            { value: '‌CIRS', text: "‌CIRS" },
            { value: '‌COM', text: "‌COM" },
            { value: '‌Control de Productos Salar', text: "‌Control de Productos Salar" },
            { value: '‌COPEC MOP I', text: "‌COPEC MOP I" },
            { value: '‌COPEC MOP II', text: "‌COPEC MOP II" },
            { value: '‌Despacho AB', text: "‌Despacho AB" },
            { value: '‌Despacho CD', text: "‌Despacho CD" },
            { value: '‌Despacho Salar', text: "‌Despacho Salar" },
            { value: '‌Encarpadora Romana 2', text: "‌Encarpadora Romana 2" },
            { value: '‌Estacionamientos Buses', text: "‌Estacionamientos Buses" },
            { value: '‌Garita Eco', text: "‌Garita Eco" },
            { value: '‌Garita Oscar', text: "‌Garita Oscar" },
            { value: '‌Gerencia Hidrogeología', text: "‌Gerencia Hidrogeología" },
            { value: '‌Gerencia Proyectos y Mantención', text: "‌Gerencia Proyectos y Mantención" },
            { value: '‌Laboratorio Metalúrgico', text: "‌Laboratorio Metalúrgico" },
            { value: 'Laboratorio Químico', text: "Laboratorio Químico" },
            { value: '‌Mina Norte', text: "Mina Norte" },
            { value: '‌Mina Sur', text: "‌Mina Sur" },
            { value: '‌Oficinas Cosecha/Pozas Litio/Pozas MOPI', text: "‌Oficinas Cosecha/Pozas Litio/Pozas MOPI" },
            { value: '‌Patio Contratista MOP II', text: "‌Patio Contratista MOP II" },
            { value: '‌Patio Contratistas MOP I', text: "‌Patio Contratistas MOP I" },
            { value: '‌Patio de Maniobras Planta MOP I / Pozas', text: "‌Patio de Maniobras Planta MOP I / Pozas" },
            { value: '‌Patio Operaciones Excon MOP I', text: "‌Patio Operaciones Excon MOP I" },
            { value: '‌Patio Operaciones Excon MOP II', text: "‌Patio Operaciones Excon MOP II" },
            { value: '‌Patio Operaciones MOPI - Litio', text: "‌Patio Operaciones MOPI - Litio" },
            { value: '‌Patio Operaciones Pozas', text: "‌Patio Operaciones Pozas" },
            { value: '‌PDA 3', text: "‌PDA 3" },
            { value: '‌PDA 4', text: "‌PDA 4" },
            { value: '‌PDA 5', text: "‌PDA 5" },
            { value: '‌PDA 8', text: "‌PDA 8" },
            { value: '‌Planta Acido Bórico', text: "‌Planta Acido Bórico" },
            { value: '‌Planta MOP G II', text: "‌Planta MOP G II" },
            { value: '‌Planta MOP G III', text: "‌Planta MOP G III" },
            { value: '‌Planta MOP H II', text: "‌Planta MOP H II" },
            { value: '‌Planta MOP HI', text: "‌Planta MOP HI" },
            { value: '‌Planta MOP STD', text: "‌Planta MOP STD" },
            { value: '‌Planta PCI', text: "‌Planta PCI" },
            { value: '‌Planta Piloto', text: "‌Planta Piloto" },
            { value: '‌Planta SOP H', text: "‌Planta SOP H" },
            { value: '‌Plantas TAS', text: "‌Plantas TAS" },
            { value: '‌Policlínico MOP I', text: "‌Policlínico MOP I" },
            { value: '‌Pozas Litio', text: "‌Pozas Litio" },
            { value: '‌POZAS MOP / SOP III', text: "‌POZAS MOP / SOP III" },
            { value: '‌Pozas MOP I', text: "‌Pozas MOP I" },
            { value: '‌Romana 1 MOP I', text: "‌Romana 1 MOP I" },
            { value: '‌Romana 2 MOP I', text: "‌Romana 2 MOP I" },
            { value: '‌Romana 4 MOP II', text: "‌Romana 4 MOP II" },
            { value: '‌Ruta 5 Norte', text: "‌Ruta 5 Norte" },
            { value: '‌Ruta B385', text: "‌Ruta B385" },
            { value: '‌Ruta Salar Toconao', text: "‌Ruta Salar Toconao" },
            { value: '‌Subestación MINSAL', text: "‌Subestación MINSAL" },
            { value: '‌Taller de Mantención Mina', text: "‌Taller de Mantención Mina" },
            { value: '‌Taller de Mantención MOP II', text: "‌Taller de Mantención MOP II" },
            { value: '‌Taller de Soldadura', text: "‌Taller de Soldadura" },
            { value: '‌Taller Mantención Eléctrica MOP I', text: "‌Taller Mantención Eléctrica MOP I" },
            { value: '‌Taller Mantención Hidrogeología', text: "‌Taller Mantención Hidrogeología" },
            { value: '‌Taller Mantención Instrumentación MOP I', text: "‌Taller Mantención Instrumentación MOP I" },
            { value: '‌Taller Mantención Mecánica MOP I', text: "‌Taller Mantención Mecánica MOP I" },
            { value: '‌Taller Mecánico', text: "‌Taller Mecánico" }
          ]
          let riesgo_options = [
            { value: null, text: "Seleccione una opción" },
            { value: 'Aplastamiento', text: "Aplastamiento" },
            { value: '‌Aprisionamiento', text: "‌Aprisionamiento" },
            { value: '‌Atrapamiento', text: "‌Atrapamiento" },
            { value: 'Atropello de Animales', text: "Atropello de Animales" },
            { value: '‌Atropello de Personas', text: "‌Atropello de Personas" },
            { value: '‌Caída de Distinto Nivel', text: "‌Caída de Distinto Nivel" },
            { value: '‌Caída Mismo Nivel', text: "‌Caída Mismo Nivel" },
            { value: '‌Camión en Panne', text: "‌Camión en Panne" },
            { value: '‌Choque', text: "‌Choque" },
            { value: '‌Colisión / Choque de vehículo liviano', text: "‌Colisión / Choque de vehículo liviano" },
            { value: '‌Colisión / Choque de Vehículo pesado', text: "‌Colisión / Choque de Vehículo pesado" },
            { value: 'Condición sub-estándar de Activo', text: "Condición sub-estándar de Activo" },
            { value: '‌Contacto con Alta Temperatura', text: "‌Contacto con Alta Temperatura" },
            { value: '‌Contacto con Energía Eléctrica', text: "‌Contacto con Energía Eléctrica" },
            { value: '‌Contacto con Objeto Cortopunzante', text: "‌Contacto con Objeto Cortopunzante" },
            { value: '‌Contacto con Partículas Incandescentes', text: "‌Contacto con Partículas Incandescentes" },
            { value: '‌Contacto con Sustancia Química', text: "‌Contacto con Sustancia Química" },
            { value: 'Contacto con Sustancias Infecciosas', text: "Contacto con Sustancias Infecciosas" },
            { value: '‌Contacto por Alta Temperatura', text: "‌Contacto por Alta Temperatura" },
            { value: '‌Contacto por Energía Eléctrica', text: "‌Contacto por Energía Eléctrica" },
            { value: '‌Contacto por Objeto Cortopunzante', text: "‌Contacto por Objeto Cortopunzante" },
            { value: '‌Contacto por Partículas Incandescentes', text: "‌Contacto por Partículas Incandescentes" },
            { value: '‌Contacto por Sustancia Química', text: "‌Contacto por Sustancia Química" },
            { value: '‌Contacto por Sustancias Infecciosas', text: "‌Contacto por Sustancias Infecciosas" },
            { value: 'Continuidad Operacional', text: "Continuidad Operacional" },
            { value: '‌CORONAVIRUS', text: "‌CORONAVIRUS" },
            { value: '‌Exposición a Gases Tóxicos', text: "‌Exposición a Gases Tóxicos" },
            { value: 'Exposición a lugar con falta de Oxígeno', text: "Exposición a lugar con falta de Oxígeno" },
            { value: 'Exposición a Niveles de Presión Anormales', text: "Exposición a Niveles de Presión Anormales" },
            { value: '‌Exposición a Niveles Nocivos de Ruido', text: "‌Exposición a Niveles Nocivos de Ruido" },
            { value: 'Exposición a Niveles Nocivos de Vibraciones', text: "Exposición a Niveles Nocivos de Vibraciones" },
            { value: '‌Exposición a partículas nocivas en suspensión', text: "‌Exposición a partículas nocivas en suspensión" },
            { value: '‌Exposición a Radiación UV', text: "‌Exposición a Radiación UV" },
            { value: '‌Exposición a Radiaciones Ionizantes', text: "‌Exposición a Radiaciones Ionizantes" },
            { value: 'Exposición a Temperaturas Extremas', text: "Exposición a Temperaturas Extremas" },
            { value: '‌Golpeado Contra', text: "‌Golpeado Contra" },
            { value: '‌Golpeado Por', text: "‌Golpeado Por" },
            { value: '‌Incendio', text: "‌Incendio" },
            { value: 'Intoxicación', text: "Intoxicación" },
            { value: 'Picadura de Insecto', text: "Picadura de Insecto" },
            { value: '‌Prendimiento de Ropa', text: "‌Prendimiento de Ropa" },
            { value: '‌Quiebre de Abrazadera', text: "‌Quiebre de Abrazadera" },
            { value: '‌Rotura de flexible', text: "‌Rotura de flexible" },
            { value: '‌Sobre Esfuerzo', text: "‌Sobre Esfuerzo" },
            { value: '‌Volcamiento de Vehículo', text: "‌Volcamiento de Vehículo" },
          ]
          this.findFieldByName(this.incidentes.fields, 'area_trabajo').options = area_trabajo_options
          this.findFieldByName(this.incidentes.fields, 'riesgo').options = riesgo_options
        }
        else {
          let area_trabajo_options = [
            { value: null, text: "Seleccione una opción" },
            { value: 'Administracion de Campamentos', text: "Administracion de Campamentos" },
            { value: 'Área de Micronizado', text: "Área de Micronizado" },
            { value: '‌Barrio Contratistas', text: "‌Barrio Contratistas" },
            { value: '‌Bodega de Envasado de Carbonato', text: "‌Bodega de Envasado de Carbonato" },
            { value: '‌Bodega de Envasado de Hidróxido', text: "‌Bodega de Envasado de Hidróxido" },
            { value: '‌Bodega de Salar del Carmen', text: "‌Bodega de Salar del Carmen" },
            { value: '‌Casino Carbonato de Litio', text: "‌Casino Carbonato de Litio" },
            { value: '‌Descarga de Solución de Litio', text: "‌Descarga de Solución de Litio" },
            { value: '‌Embarque de Carbonato', text: "‌Embarque de Carbonato" },
            { value: '‌Embarque de Hidróxido', text: "‌Embarque de Hidróxido" },
            { value: '‌Envasado y Despacho', text: "‌Envasado y Despacho" },
            { value: 'Galpón de Ceniza', text: "Galpón de Ceniza" },
            { value: '‌Gerencia de Procesos', text: "‌Gerencia de Procesos" },
            { value: '‌Gerencia de Produccion Litio', text: "‌Gerencia de Produccion Litio" },
            { value: '‌Gerencia de Proyecto y Mantención', text: "‌Gerencia de Proyecto y Mantención" },
            { value: '‌Laboratorio Quimico', text: "‌Laboratorio Quimico" },
            { value: 'Mantención Eléctrica', text: "Mantención Eléctrica" },
            { value: 'Mantención Mecánica', text: "Mantención Mecánica" },
            { value: '‌Patio de Maxi sacos', text: "‌Patio de Maxi sacos" },
            { value: '‌Patio de Pallet', text: "‌Patio de Pallet" },
            { value: '‌Patio de residuos', text: "‌Patio de residuos" },
            { value: '‌Planta Carbonato', text: "‌Planta Carbonato" },
            { value: '‌Planta Hidróxido', text: "‌Planta Hidróxido" },
            { value: '‌Planta Piloto', text: "‌Planta Piloto" },
            { value: '‌Planta SX', text: "‌Planta SX" },
            { value: '‌Pozas de Descartes', text: "‌Pozas de Descartes" },
            { value: '‌Pozas de Litio', text: "‌Pozas de Litio" },
            { value: '‌Romana', text: "‌Romana" },
            { value: '‌Sala de Cambio de Carbonato', text: "‌Sala de Cambio de Carbonato" },
            { value: '‌Taller de Mantención', text: "‌Taller de Mantención" },
          ]
          let riesgo_options = [
            { value: null, text: "Seleccione una opción" },
            { value: 'Aplastamiento', text: "Aplastamiento" },
            { value: '‌Aprisionamiento', text: "‌Aprisionamiento" },
            { value: '‌Atrapamiento', text: "‌Atrapamiento" },
            { value: 'Atropello de Animales', text: "Atropello de Animales" },
            { value: '‌Atropello de Personas', text: "‌Atropello de Personas" },
            { value: '‌Caída de Distinto Nivel', text: "‌Caída de Distinto Nivel" },
            { value: '‌Caída Mismo Nivel', text: "‌Caída Mismo Nivel" },
            { value: '‌Camión en Panne', text: "‌Camión en Panne" },
            { value: '‌Choque', text: "‌Choque" },
            { value: '‌Colisión / Choque de vehículo liviano', text: "‌Colisión / Choque de vehículo liviano" },
            { value: '‌Colisión / Choque de Vehículo pesado', text: "‌Colisión / Choque de Vehículo pesado" },
            { value: 'Condición sub-estándar de Activo', text: "Condición sub-estándar de Activo" },
            { value: '‌Contacto con Alta Temperatura', text: "‌Contacto con Alta Temperatura" },
            { value: '‌Contacto con Energía Eléctrica', text: "‌Contacto con Energía Eléctrica" },
            { value: '‌Contacto con Objeto Cortopunzante', text: "‌Contacto con Objeto Cortopunzante" },
            { value: '‌Contacto con Partículas Incandescentes', text: "‌Contacto con Partículas Incandescentes" },
            { value: '‌Contacto con Sustancia Química', text: "‌Contacto con Sustancia Química" },
            { value: 'Contacto con Sustancias Infecciosas', text: "Contacto con Sustancias Infecciosas" },
            { value: '‌Contacto por Alta Temperatura', text: "‌Contacto por Alta Temperatura" },
            { value: '‌Contacto por Energía Eléctrica', text: "‌Contacto por Energía Eléctrica" },
            { value: '‌Contacto por Objeto Cortopunzante', text: "‌Contacto por Objeto Cortopunzante" },
            { value: '‌Contacto por Partículas Incandescentes', text: "‌Contacto por Partículas Incandescentes" },
            { value: '‌Contacto por Sustancia Química', text: "‌Contacto por Sustancia Química" },
            { value: '‌Contacto por Sustancias Infecciosas', text: "‌Contacto por Sustancias Infecciosas" },
            { value: 'Continuidad Operacional', text: "Continuidad Operacional" },
            { value: '‌CORONAVIRUS', text: "‌CORONAVIRUS" },
            { value: '‌Exposición a Gases Tóxicos', text: "‌Exposición a Gases Tóxicos" },
            { value: 'Exposición a lugar con falta de Oxígeno', text: "Exposición a lugar con falta de Oxígeno" },
            { value: 'Exposición a Niveles de Presión Anormales', text: "Exposición a Niveles de Presión Anormales" },
            { value: '‌Exposición a Niveles Nocivos de Ruido', text: "‌Exposición a Niveles Nocivos de Ruido" },
            { value: 'Exposición a Niveles Nocivos de Vibraciones', text: "Exposición a Niveles Nocivos de Vibraciones" },
            { value: '‌Exposición a partículas nocivas en suspensión', text: "‌Exposición a partículas nocivas en suspensión" },
            { value: '‌Exposición a Radiación UV', text: "‌Exposición a Radiación UV" },
            { value: '‌Exposición a Radiaciones Ionizantes', text: "‌Exposición a Radiaciones Ionizantes" },
            { value: 'Exposición a Temperaturas Extremas', text: "Exposición a Temperaturas Extremas" },
            { value: '‌Golpeado Contra', text: "‌Golpeado Contra" },
            { value: '‌Golpeado Por', text: "‌Golpeado Por" },
            { value: '‌Incendio', text: "‌Incendio" },
            { value: 'Intoxicación', text: "Intoxicación" },
            { value: 'Picadura de Insecto', text: "Picadura de Insecto" },
            { value: '‌Prendimiento de Ropa', text: "‌Prendimiento de Ropa" },
            { value: '‌Quiebre de Abrazadera', text: "‌Quiebre de Abrazadera" },
            { value: '‌Rotura de flexible', text: "‌Rotura de flexible" },
            { value: '‌Sobre Esfuerzo', text: "‌Sobre Esfuerzo" },
            { value: '‌Volcamiento de Vehículo', text: "‌Volcamiento de Vehículo" }

          ]
          this.findFieldByName(this.incidentes.fields, 'area_trabajo').options = area_trabajo_options
          this.findFieldByName(this.incidentes.fields, 'riesgo').options = riesgo_options
        }
      }
    },
    findFieldByName(fields, name) {
      return fields.find(field => field.id == name)
    },


  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>
