<template>
  <div>
    <b-row>
      <b-col>
        <BrunaForm ref="irVerRef" :fields="this.irVer.fields" :card="this.irVer.card" @change="faenairVerChange" :datePickerFormatOptions="datePickerFormatOptions">
          <template v-slot:footer>
            <b-button variant="primary" class="mt-4 float-right" @click="addNewHallazgo">Agregar</b-button>
          </template>
        </BrunaForm>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col xl="12" md="12">
        <!-- table -->
        <ag-grid-table ref="tablaHistoricoIrVer" :configUrl="historicoIrVerConfig" :helper="true"
          :dataUrl="historicoIrVerData">
        </ag-grid-table>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";
import axios from "@axios";
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import useApiServices from '@/services/useApiServices.js';
import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import EliminarActions from "./actions/EliminarActions.vue";

export default {
  data() {
    return {
      historicoIrVerConfig: useApiServices.historicoIrVerConfig,
      historicoIrVerData: useApiServices.historicoIrVerData,
      itemsData: [],
      datePickerFormatOptions: {'year': 'numeric', 'month': '2-digit', 'day': '2-digit'},
      irVer: {
        card: {
          title: "Ir y Ver",
          subtitle: "",
          tooltip: "",
          sidebarContent: {
            title: 'Ir y Ver',
            body: "Para poder hacer un reporte de Ir y Ver debe rellenar los campos y luego hacer clic en botón “agregar”."
          }
        },
        fields: [
          {
            label: "Fecha",
            type: "datepicker",
            id: 'fecha',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Faena",
            type: "select",
            id: 'faena',
            rules: 'required',
            options: [
              { value: null, text: "Seleccione una opción" },
              { value: 'Planta Química de Litio Carmen', text: "Planta Química de Litio Carmen" },
              { value: 'Salar de Atacama', text: "Salar de Atacama" },
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Área de trabajo",
            type: "select",
            id: 'area_trabajo',
            rules: 'required',
            options: [
              { value: null, text: "Seleccione una opción" }
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Tipo de Programa",
            type: "select",
            id: 'tipo_programa',
            rules: 'required',
            options: [
              { value: null, text: "Seleccione una opción" }
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          }
        ]
      },
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    AgGridTable,
    BrunaForm,
    ToastificationContent,
    EliminarActions:EliminarActions
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted() {

  },
  methods: {
    addNewHallazgo(event) {
      let data = this.irVer.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})
      Promise.all([
        this.$refs.irVerRef.validate()
      ]).then(([irVerValid]) => {
        if (irVerValid) {
          useApiServices.postIrVerCreate(data)
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Carga Exitosa`,
                  icon: "UploadIcon",
                  variant: "success",
                  html: `Se ha guardado el nuevo registro exitosamente.`,
                },
              });
              this.$refs['tablaHistoricoIrVer'].refreshRows()
            })
            .catch((error) => {
              let message = error;

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Ocurrio un Error`,
                  icon: "UploadIcon",
                  variant: "danger",
                  html: message,
                },
              });

            });
        }
      });
    },
    faenairVerChange(field) {
      if (field.id == 'faena') {
        let area_trabajo_options = []
        let tipo_programa_options = []
        if (field.value == "Salar de Atacama") {
          area_trabajo_options = [
            { value: null, text: "Seleccione una opción" },
            { value: 'Acopios', text: "Acopios" },
            { value: 'Acopios MOP H I', text: "Acopios MOP H I" },
            { value: 'Antenas de comunicaciones', text: "Antenas de comunicaciones" },
            { value: 'Bodega Bosata', text: "Bodega Bosata" },
            { value: 'Bodega Cirs', text: "Bodega Cirs" },
            { value: 'Bodega de Proyectos', text: "Bodega de Proyectos" },
            { value: 'Bodega Proyectos', text: "Bodega Proyectos" },
            { value: 'Bodegas', text: "Bodegas" },
            { value: 'Camino Interplantas', text: "Camino Interplantas" },
            { value: 'Camino Principal', text: "Camino Principal" },
            { value: 'Campamento Andino', text: "Campamento Andino" },
            { value: 'Canaleta 3', text: "Canaleta 3" },
            { value: 'Canaleta Arpes', text: "Canaleta Arpes" },
            { value: 'Canaleta E02', text: "Canaleta E02" },
            { value: 'Canchas de Producto', text: "Canchas de Producto" },
            { value: 'Cañón del Diablo', text: "Cañón del Diablo" },
            { value: 'Casino MOP I', text: "Casino MOP I" },
            { value: 'Casino MOP II', text: "Casino MOP II" },
            { value: 'CIRS', text: "CIRS" },
            { value: 'COM', text: "COM" },
            { value: 'Control de Productos Salar', text: "Control de Productos Salar" },
            { value: 'Despacho AB', text: "Despacho AB" },
            { value: 'Despacho CD', text: "Despacho CD" },
            { value: 'Despacho Salar', text: "Despacho Salar" },
            { value: 'Encarpadora Romana 2', text: "Encarpadora Romana 2" },
            { value: 'Estacionamientos Buses', text: "Estacionamientos Buses" },
            { value: 'Garita Oscar', text: "Garita Oscar" },
            { value: 'Gerencia Hidrogeología', text: "Gerencia Hidrogeología" },
            { value: 'Gerencia KCL', text: "Gerencia KCL" },
            { value: 'Gerencia Proyectos y Mantención', text: "Gerencia Proyectos y Mantención" },
            { value: 'Laboratorio Metalúrgico', text: "Laboratorio Metalúrgico" },
            { value: 'Laboratorio Químico', text: "Laboratorio Químico" },
            { value: 'Mina Norte', text: "Mina Norte" },
            { value: 'Mina Sur', text: "Mina Sur" },
            { value: 'Oficinas Cosecha/Pozas Litio/Pozas MOPI', text: "Oficinas Cosecha/Pozas Litio/Pozas MOPI" },
            { value: 'Patio Contratista MOP II', text: "Patio Contratista MOP II" },
            { value: 'Patio Contratistas MOP I', text: "Patio Contratistas MOP I" },
            { value: 'Patio Operaciones Excon MOP I', text: "Patio Operaciones Excon MOP I" },
            { value: 'Patio Operaciones Excon MOP II', text: "Patio Operaciones Excon MOP II" },
            { value: 'Patio Operaciones MOPI - Litio', text: "Patio Operaciones MOPI - Litio" },
            { value: 'Patio Operaciones Pozas', text: "Patio Operaciones Pozas" },
            { value: 'PDA 3', text: "PDA 3" },
            { value: 'PDA 4', text: "PDA 4" },
            { value: 'PDA 5', text: "PDA 5" },
            { value: 'PDA 7', text: "PDA 7" },
            { value: 'PDA 8', text: "PDA 8" },
            { value: 'Planta Acido Bórico', text: "Planta Acido Bórico" },
            { value: 'Planta MOP G II', text: "Planta MOP G II" },
            { value: 'Planta MOP G III', text: "Planta MOP G III" },
            { value: 'Planta MOP H II', text: "Planta MOP H II" },
            { value: 'Planta MOP HI', text: "Planta MOP HI" },
            { value: 'Planta MOP STD', text: "Planta MOP STD" },
            { value: 'Planta PAM', text: "Planta PAM" },
            { value: 'Planta PCI', text: "Planta PCI" },
            { value: 'Planta Piloto', text: "Planta Piloto" },
            { value: 'Planta SOP H', text: "Planta SOP H" },
            { value: 'Poza PBS', text: "Poza PBS" },
            { value: 'Pozas Litio', text: "Pozas Litio" },
            { value: 'POZAS MOP / SOP III', text: "POZAS MOP / SOP III" },
            { value: 'Pozas MOP I', text: "Pozas MOP I" },
            { value: 'Romana 1 MOP I', text: "Romana 1 MOP I" },
            { value: 'Romana 2 MOP I', text: "Romana 2 MOP I" },
            { value: 'Romana 3 MOP II', text: "Romana 3 MOP II" },
            { value: 'Romana 4 MOP II', text: "Romana 4 MOP II" },
            { value: 'RRHH KCL', text: "RRHH KCL" },
            { value: 'Ruta B385', text: "Ruta B385" },
            { value: 'Subestación condensadores 2', text: "Subestación condensadores 2" },
            { value: 'Subestación MINSAL', text: "Subestación MINSAL" },
            { value: 'Taller de Mantención Mina', text: "Taller de Mantención Mina" },
            { value: 'Taller de Mantención MOP II', text: "Taller de Mantención MOP II" },
            { value: 'Taller de Soldadura', text: "Taller de Soldadura" },
            { value: 'Taller Eléctrico', text: "Taller Eléctrico" },
            { value: 'Taller Instrumentación', text: "Taller Instrumentación" },
            { value: 'Taller Mantención Eléctrica MOP I', text: "Taller Mantención Eléctrica MOP I" },
            { value: 'Taller Mantención Hidrogeología', text: "Taller Mantención Hidrogeología" },
            { value: 'Taller Mantención Instrumentación MOP I', text: "Taller Mantención Instrumentación MOP I" },
            { value: 'Taller Mantención Mecánica MOP I', text: "Taller Mantención Mecánica MOP I" },
            { value: 'Taller Mecánico', text: "Taller Mecánico" },
            { value: 'Terrapuerto', text: "Terrapuerto" }
          ]
          tipo_programa_options = [
            { value: null, text: "Seleccione una opción" },
            { value: 'Programa Personalizado de Supervisión', text: "Programa Personalizado de Supervisión" },
            { value: 'Plan de Acción de Investigación de Incidentes', text: "Plan de Acción de Investigación de Incidentes" },
          ]
          this.findFieldByName(this.irVer.fields, 'area_trabajo').options = area_trabajo_options
          this.findFieldByName(this.irVer.fields, 'tipo_programa').options = tipo_programa_options
        }
        else {
          area_trabajo_options = [
            { value: null, text: "Seleccione una opción" },
            { value: 'Administracion de Campamentos', text: "Administracion de Campamentos" },
            { value: 'Area de Micronizado', text: "Area de Micronizado" },
            { value: 'Barrio Contratistas ', text: "Barrio Contratistas " },
            { value: 'Bodega de Envasado de Carbonato', text: "Bodega de Envasado de Carbonato" },
            { value: 'Bodega de Envasado de Hidróxido', text: "Bodega de Envasado de Hidróxido" },
            { value: 'Bodega de Salar del Carmen ', text: "Bodega de Salar del Carmen " },
            { value: 'Bodega de sustancias Peligrosas', text: "Bodega de sustancias Peligrosas" },
            { value: 'Casino Carbonato de Litio', text: "Casino Carbonato de Litio" },
            { value: 'Descarga de Solución de Litio', text: "Descarga de Solución de Litio" },
            { value: 'Embarque de Carbonato', text: "Embarque de Carbonato" },
            { value: 'Embarque de Hidróxido', text: "Embarque de Hidróxido" },
            { value: 'Envasado y Despacho', text: "Envasado y Despacho" },
            { value: 'Estacion de servicio', text: "Estacion de servicio" },
            { value: 'Galpon de Ceniza', text: "Galpon de Ceniza" },
            { value: 'Gerencia de Procesos', text: "Gerencia de Procesos" },
            { value: 'Gerencia de Produccion Litio', text: "Gerencia de Produccion Litio" },
            { value: 'Gerencia de Proyecto y Mantención', text: "Gerencia de Proyecto y Mantención" },
            { value: 'Laboratorio Quimico', text: "Laboratorio Quimico" },
            { value: 'Mantención Eléctrica', text: "Mantención Eléctrica" },
            { value: 'Mantención Mecánica', text: "Mantención Mecánica" },
            { value: 'Patio de Maxi sacos', text: "Patio de Maxi sacos" },
            { value: 'Patio de Pallet', text: "Patio de Pallet" },
            { value: 'Patio de residuos ', text: "Patio de residuos " },
            { value: 'Planificacion Mantención', text: "Planificacion Mantención" },
            { value: 'Planta Carbonato', text: "Planta Carbonato" },
            { value: 'Planta Hidróxido', text: "Planta Hidróxido" },
            { value: 'Planta Piloto', text: "Planta Piloto" },
            { value: 'Planta SX', text: "Planta SX" },
            { value: 'Pozas de Descartes', text: "Pozas de Descartes" },
            { value: 'Pozas de Litio', text: "Pozas de Litio" },
            { value: 'Romana', text: "Romana" },
            { value: 'Sala de Cambio de Carbonato', text: "Sala de Cambio de Carbonato" },
            { value: 'Sala de Cambio de Hidróxido', text: "Sala de Cambio de Hidróxido" },
            { value: 'Taller de Mantención', text: "Taller de Mantención" },
            { value: 'TK de Acido Sulfurico', text: "TK de Acido Sulfurico" }
          ]
          tipo_programa_options = [
            { value: null, text: "Seleccione una opción" },
            { value: 'Programa Personalizado de Supervisión', text: "Programa Personalizado de Supervisión" },
            { value: 'Plan de Acción de Investigación de Incidentes', text: "Plan de Acción de Investigación de Incidentes" },
            { value: 'Programa de Liderazgo Percibido', text: "Programa de Liderazgo Percibido" },
          ]
          this.findFieldByName(this.irVer.fields, 'area_trabajo').options = area_trabajo_options
          this.findFieldByName(this.irVer.fields, 'tipo_programa').options = tipo_programa_options
        }
      }
    },
    findFieldByName(fields, name) {
      return fields.find(field => field.id == name)
    },
  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>
